import React, { useState, useReducer } from "react";

function useFilters() {
  const initialState = [];

  function currentTagsReducer(state, action) {
    switch (action.type) {
      case "addTags":
        return [...state, ...action.payload];
      case "addTag":
        return [...state, action.payload];
      case "removeTag":
        return state.filter(item => item !== action.payload);
      default:
        throw new Error();
    }
  }

  const [currentTags, dispatch] = useReducer(currentTagsReducer, initialState);

  const [userPosition, setUserPosition] = useState(null);
  const [isHidingClosedLocations, setHideClosedLocations] = useState(false);

  const [distanceRestriction, setDistanceRestriction] = useState(0);

  const setCurrentTags = tag => dispatch({ type: "addTag", payload: tag });
  const unselectTag = tag => dispatch({ type: "removeTag", payload: tag });

  React.useEffect(() => {
    if (distanceRestriction !== 0) {
      navigator.geolocation.getCurrentPosition(
        function(position) {
          setUserPosition({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        function() {
          setDistanceRestriction(0);
          alert(
            "We were unable to determine your current location. Distance Filters have been reset."
          );
        }
      );
    }
  }, [distanceRestriction]);

  React.useEffect(() => {
    const a = new URLSearchParams(window.location.search);
    const tags = a.get("tags");

    if (tags) {
      const parsedTags = decodeURIComponent(tags).split(",");
      dispatch({ type: "addTags", payload: parsedTags });
    }
  }, []);

  return {
    setCurrentTags,
    unselectTag,
    currentTags,
    userPosition,
    setUserPosition,
    isHidingClosedLocations,
    setHideClosedLocations,
    distanceRestriction,
    setDistanceRestriction,
  };
}

export default useFilters;
