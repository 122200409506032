import React, { createContext } from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

import useFilters from "../hooks/useFilters";

const FilterContext = createContext();

const FilterProvider = function({ children }) {
  const {
    setCurrentTags,
    unselectTag,
    currentTags,
    userPosition,
    setUserPosition,
    isHidingClosedLocations,
    setHideClosedLocations,
    distanceRestriction,
    setDistanceRestriction,
  } = useFilters();

  const { allTag, allServiceTag } = useStaticQuery(graphql`
    query FilterQuery {
      allTag {
        nodes {
          name
          count
        }
      }
      allServiceTag {
        nodes {
          name
          count
        }
      }
    }
  `);

  return (
    <FilterContext.Provider
      value={{
        setCurrentTags,
        unselectTag,
        tags: allTag.nodes,
        serviceTags: allServiceTag.nodes,
        currentTags,
        userPosition,
        setUserPosition,
        isHidingClosedLocations,
        setHideClosedLocations,
        distanceRestriction,
        setDistanceRestriction,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

FilterProvider.propTypes = {
  children: PropTypes.element,
};

export default FilterContext;
export { FilterProvider };
